import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20 Box Jumps (24/20)`}</p>
    <p>{`15 Burpees Over Bar`}</p>
    <p>{`10 Deadlifts (225/155)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`100ft Sled Pull (+135/90)`}</p>
    <p>{`15 KB SDHP’s (53/35)`}</p>
    <p><em parentName="p">{`*`}{`*`}{`compare both to 5/27/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am on the
28th so sign up ahead of time!  If you are a visitor and won’t be in
before Murph you can email us and we’ll sign you up.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Regionals are live this weekend.  Watch the action at
Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Sunday, May 20th.  For more
info email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      